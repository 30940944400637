<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <!-- breadcrumb -->
      <div class="col-12">
        <ul class="breadcrumb">
          <li class="breadcrumb__item"><router-link to="/en">Home</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">License</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <div class="row row--grid">
        <!-- dashbox -->
        <div class="col-12">
          <div class="dashbox">
            <div class="dashbox__title">
              <h3>License</h3>

              <div class="dashbox__wrap">
                <span class="dashbox__more">※ License information for each module.</span>
              </div>
            </div>

            <div class="dashbox__list-wrap">
              <ul class="main__list main__list--dashbox">
                <li class="single-item" v-for="item in modules">
                  <div class="single-item__title">
                    <h4><a class="single-item__link" @click='showLicense(item.license)'>{{item.lang.en.title}}</a></h4>
                    <span><a class="single-item__link" @click='showLicense(item.license)'>{{item.license === 'None' ? 'None' : item.license === 'External Site' ? 'External Site' : item.license}}</a></span>
                  </div>
                  <a :href="item.href" target="_blank" class="single-item__export">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16,10.27,11,7.38A2,2,0,0,0,8,9.11v5.78a2,2,0,0,0,1,1.73,2,2,0,0,0,2,0l5-2.89a2,2,0,0,0,0-3.46ZM15,12l-5,2.89V9.11L15,12ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path></svg>
                  </a>
                  <a :href="item.source" class="single-item__add" target='_blank'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"></path></svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end dashbox -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "EN_License",
  data: function() {
    return {
      modules: store.state.modules,
    };
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250);
  },
  methods: {
    showLicense(name) {
      let url;
      switch (name) {
        case 'Apache License 2.0':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1002';
          break;
        case 'MIT':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1006';
          break;
        case 'Creative Commons Attribution 4.0':
          url = 'https://creativecommons.org/licenses/by/4.0/deed.ko';
          break;
      }

      if (url) {
        window.open(url, '_blank');
      }
    }
  }
}
</script>
